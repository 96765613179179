import { PriceSet } from '../../core/components/orderSummary';
import React from 'react';
import { BaseCheckoutProjectItem } from './baseCheckoutProjectItem';
import { components } from 'outlinejs';

const { Link } = components;

export class AeGuideProjectItem extends BaseCheckoutProjectItem {
  renderProjectItem() {
    const {
      productName,
      quantity,
      unitaryFormattedPrice,
      unitaryFullFormattedPrice,
      totalFormattedPrice,
      totalFullFormattedPrice
    } = this.props.preorder;

    return (
      <div className="row">
        <div className="col-xs-9 col-sm-4">
          <h4 className="text-uppercase">{productName}</h4>
        </div>
        <div className="col-xs-3 text-right hidden-sm hidden-md hidden-lg padding-down-20">
          <Link state="cart:main" params={{ projectId: this.props.project.id }}>
            <div className="cart-project-redirect fa-stack fa-lg green">
              <i className="fa fa-circle fa-stack-2x " />
              <i className="fa fa-pencil-square-o fa-inverse fa-stack-1x" />
            </div>
          </Link>
        </div>
        <div className="col-xs-12 col-sm-3 cart-project-prices-element">
          <div className="row">
            <div className="col-xs-5 hidden-sm hidden-md hidden-lg">
              <span className="price-labels">{this.i18n.gettext('quantità')}</span>
            </div>
            <div className="col-xs-7 col-sm-12 text-center">
              <strong>{quantity}</strong>
            </div>
            <div className="col-xs-12 hidden-sm hidden-md hidden-lg">
              <hr className="cart-project-prices-header-divider" />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-2 cart-project-prices-element">
          <div className="row">
            <div className="col-xs-5 hidden-sm hidden-md hidden-lg">
              <span className="price-labels">{this.i18n.gettext('prezzo unitario')}</span>
            </div>
            <div className="col-xs-7 col-sm-12 unitary_prices text-xs-right text-sm-center">
              <PriceSet fullprice={unitaryFullFormattedPrice} price={unitaryFormattedPrice} />
            </div>
            <div className="col-xs-12 hidden-sm hidden-md hidden-lg">
              <hr className="cart-project-prices-header-divider" />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-2 cart-project-prices-element">
          <div className="row">
            <div className="col-xs-5 hidden-sm hidden-md hidden-lg price-labels">
              <span className="price-labels">{this.i18n.gettext('totale')}</span>
            </div>
            <div className="col-xs-7 col-sm-12 total_prices text-xs-right text-sm-center">
              <PriceSet fullprice={totalFullFormattedPrice} price={totalFormattedPrice} />
            </div>
          </div>
        </div>
        <div className="col-sm-1 hidden-xs">
          <Link state="cart:main" params={{ projectId: this.props.project.id }}>
            <div className="cart-project-redirect fa-stack fa-lg green">
              <i className="fa fa-circle fa-stack-2x " />
              <i className="fa fa-pencil-square-o fa-inverse fa-stack-1x" />
            </div>
          </Link>
        </div>
      </div>
    );
  }

  render() {
    return super.render();
  }
}
