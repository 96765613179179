import { components } from 'outlinejs';
import React from 'react';

export class DeliveryDaysComponent extends components.BaseComponent {
  render() {
    // uncomment these lines to show Christmas delivery times
    // let deliveryUrl = conf.settings.SHIPPING_URLS[this.request.language];

    return (
      <div>
        <p>
          {this.props.days} {this.i18n.gettext('giorni lavorativi')}
        </p>
        {/* <p className='delivery-helper'>{this.i18n.gettext('In December estimated delivery day may vary (for more detail ')}<span><a href={deliveryUrl} target='_blank'>{this.i18n.gettext('click here')}</a></span>)</p> */}
      </div>
    );
  }
}
