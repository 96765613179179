import { runtime } from '@outlinejs/contexts';
import { routing } from 'outlinejs';

import { CartCheckoutBaseController } from '../core/controllers';
import Logger from '../core/logger';
import { BillingAddress, ShippingAddress } from '../users/models';
import { GuiNotification } from '../core/notifications';

import { CheckoutContentView } from './views';
import EventTracker from '../core/eventTracker';
import { CustomError } from '../core/errors';

export class CheckoutController extends CartCheckoutBaseController {
  get view() {
    return CheckoutContentView;
  }

  get context() {
    return Object.assign(super.context, {
      billingAddress: this.billingAddress,
      shippingAddress: this.shippingAddress,
      deliveryDays: this.deliveryDays
    });
  }

  async initContentProps() {
    await super.initContentProps();
  }

  async init() {
    await this.initContentProps();

    this.startInitialRendering();

    this.billingAddress = {};
    this.shippingAddress = {};
    this.deliveryDays = '';

    if (runtime.isClient) {
      if (this.user && this.customerUser) {
        if (!this.request.customerUserIsAuthorized) {
          this.response.navigate('unauthorized');
          return;
        }

        EventTracker.log(this.customerUser, 'checkout_view');

        this.isBlockingMessageActive();

        this.onEnterMessageModalIsActive();

        // load cart header must be the first API callback to ensure data consistency
        await this.loadCartHeader(true);

        if (
          this.hasCartOnlinePayment(this.cartHeader.model) &&
          this.hasPendingPayment(this.cartHeader.model)
        ) {
          this.showOrderInWaitingPaymentAlert();
        }

        await this.getCartProjects();

        if (this.cartHeader.model.numberOfProducts < 1) {
          const url = routing.Utils.reverse('cart:main').replace(/\/$/, '');
          return this.response.navigate(url);
        }

        [this.billingAddress, this.shippingAddress] = await Promise.all([
          this.getBillingAddress(this.request.customerUser.shopCode),
          this.getShippingAddress(this.request.customerUser.shopCode)
        ]);
        this.deliveryDays = this.cartHeader.model.deliveryDays;

        this.stopInitialRendering();
      }
    }
  }

  async getBillingAddress(shopCode) {
    const billingAddress = new BillingAddress({ shopCode });
    try {
      await billingAddress.fetch();
    } catch (err) {
      Logger.error('CheckoutController.getBillingAddress', {
        error: err
      });
    }
    return billingAddress;
  }

  async getShippingAddress(shopCode) {
    const shippingAddress = new ShippingAddress({ shopCode });
    try {
      await shippingAddress.fetch();
    } catch (err) {
      Logger.error('CheckoutController.getShippingAddress', {
        error: err
      });
    }
    return shippingAddress;
  }

  isCheckoutValid() {
    return this.cartHeader.model.numberOfProducts > 0;
  }

  directPayment() {
    // allow users with online payment and total price = 0 to pay with invoice
    return this.cartHeader.model.directPayment;
  }

  async confirmOnlinePayment() {
    EventTracker.log(this.customerUser, 'payment_start', { online_payment: true });

    this.navigateToPaymentView(this.cartHeader.model.id);
  }

  async confirmInvoicePayment() {
    EventTracker.log(this.customerUser, 'payment_start', { online_payment: false });
    const payment_type_id = this.customerUser.paymentType;

    try {
      this.startLoadingPage();
      // this.orderHeader.model.cartState = 'Confirmed';
      this.orderHeader.model.orderGuid = this.cartHeader.model.orderGuid;
      this.orderHeader.model.cartGuid = this.cartHeader.model.id;
      this.orderHeader.model.shopCode = this.customerUser.shopCode;

      // this event for products with total to pay equal to zero
      await this.orderHeader.model.create();

      EventTracker.log(this.customerUser, 'payment_success', {
        online_payment: false,
        order_id: this.orderHeader.model.orderId,
        payment_type_id
      });

      this.navigateToOrderConfirmationView(this.cartHeader.model.orderWebNumber);
    } catch (err) {
      const error = new CustomError(err);
      const timestamp = new Date().getTime();
      Logger.error('CheckoutController.confirmInvoicePayment - UNABLE TO CONFIRM ORDER', {
        error: err,
        errorCode: timestamp,
        guid: this.cartHeader.model.guid
      });
      GuiNotification.modalError(
        this.i18n.gettext("Non è stato possibile confermare l'ordine: ") + this.cartHeader.model.id,
        `Error detail: CheckoutController:confirmOrder - code: ${timestamp}`
      );

      EventTracker.log(this.customerUser, 'payment_error', {
        online_payment: false,
        payment_type_id,
        order_id: this.orderHeader.model.orderId,
        error_code: error.name
      });
    } finally {
      this.stopLoadingPage();
    }
  }

  goToProductList() {
    Logger.warning(
      'CheckoutController.goToProductList called from checkout controller, not implemented'
    );
  }
}
