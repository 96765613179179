import { components } from 'outlinejs';
import React from 'react';

export class CheckoutProjectItemHeader extends components.BaseComponent {
  render() {
    return (
      <div className="hidden-xs cart-project-item-header">
        <div className="row cart-project-prices-header">
          <div className="col-xs-4 cart-project-prices-title">&nbsp;</div>
          <div className="col-xs-3 cart-project-prices-title">
            <div>
              <h5>{this.i18n.gettext('quantità')}</h5>
            </div>
          </div>
          <div className="col-xs-2 cart-project-prices-title">
            <h5>{this.i18n.gettext('prezzo unitario')}</h5>
          </div>
          <div className="col-xs-2 cart-project-prices-title text-center">
            <h5>{this.i18n.gettext('totale')}</h5>
          </div>
          <div className="col-xs-1 cart-project-prices-title">&nbsp;</div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <hr className="cart-project-prices-header-divider" />
          </div>
        </div>
      </div>
    );
  }
}
