import PropTypes from 'prop-types';
import React from 'react';

export const AddressComponent = (props) => {
  const { company, street, number, zipCode, city, country } = props;
  return (
    <p>
      <strong>{company}</strong>
      <br />
      {street} {number} <br />
      {zipCode} {city} {country}
    </p>
  );
};

AddressComponent.propTypes = {
  company: PropTypes.string,
  street: PropTypes.string,
  number: PropTypes.string,
  zipCode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string
};
