import { components } from 'outlinejs';
import { ColorCorrectionPriceSet } from '../../core/components/colorCorrectionPriceSet';
import React from 'react';
import { DesignServicePriceSet } from '../../core/components/designServicePriceSet';

const { Link } = components;

class ColorCorrectionItem extends components.BaseComponent {
  render() {
    const { project, preorder } = this.props;
    return (
      <div className="row">
        <div className="col-xs-9 col-sm-9">
          <h4 className="text-uppercase">{this.i18n.gettext('correzione colore')}</h4>
          <h6 style={{ marginTop: 6 }}>
            {this.i18n.gettext(
              'Un esperto controlla ogni immagine e bilancia la luminostià ed il colore, dona nitidezza e rimuove il rumore'
            )}
          </h6>
        </div>
        <div className="col-xs-3 text-right hidden-sm hidden-md hidden-lg padding-down-20">
          <Link state="cart:main" params={{ projectId: project.id }}>
            <div className="fa-stack fa-lg green">
              <i className="fa fa-circle fa-stack-2x " />
              <i className="fa fa-pencil-square-o fa-inverse fa-stack-1x" />
            </div>
          </Link>
        </div>
        <div className="col-xs-12 col-sm-2 cart-project-prices-element">
          <div className="row">
            <div className="col-xs-5 hidden-sm hidden-md hidden-lg price-labels">
              <span className="price-labels">{this.i18n.gettext('totale')}</span>
            </div>
            <div className="col-xs-7 col-sm-12 total_prices text-xs-right text-sm-center price">
              <ColorCorrectionPriceSet
                price={preorder.colorCorrectionFormattedPrice}
                discountedPrice={preorder.colorCorrectionFormattedDiscountedPrice}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-1 hidden-xs">
          <Link state="cart:main" params={{ projectId: project.id }}>
            <div className="fa-stack fa-lg green">
              <i className="fa fa-circle fa-stack-2x " />
              <i className="fa fa-pencil-square-o fa-inverse fa-stack-1x" />
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

class DesignServiceItem extends components.BaseComponent {
  render() {
    const { project, preorder } = this.props;
    return (
      <div className="row">
        <div className="col-xs-9 col-sm-9">
          <h4 className="text-uppercase">{this.i18n.gettext('impaginazione')}</h4>
          <h6 style={{ marginTop: 6 }}>{this.i18n.gettext('Full Service Design')}</h6>
        </div>
        <div className="col-xs-3 text-right hidden-sm hidden-md hidden-lg padding-down-20">
          <Link state="cart:main" params={{ projectId: project.id }}>
            <div className="fa-stack fa-lg green">
              <i className="fa fa-circle fa-stack-2x " />
              <i className="fa fa-pencil-square-o fa-inverse fa-stack-1x" />
            </div>
          </Link>
        </div>
        <div className="col-xs-12 col-sm-2 cart-project-prices-element">
          <div className="row">
            <div className="col-xs-5 hidden-sm hidden-md hidden-lg price-labels">
              <span className="price-labels">{this.i18n.gettext('totale')}</span>
            </div>
            <div className="col-xs-7 col-sm-12 total_prices text-xs-right text-sm-center price">
              <DesignServicePriceSet
                price={preorder.designServiceFormattedPrice}
                discountedPrice={preorder.designServiceFormattedDiscountedPrice}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-1 hidden-xs">
          <Link state="cart:main" params={{ projectId: project.id }}>
            <div className="fa-stack fa-lg green">
              <i className="fa fa-circle fa-stack-2x " />
              <i className="fa fa-pencil-square-o fa-inverse fa-stack-1x" />
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export class BaseCheckoutProjectItem extends components.BaseComponent {
  constructor(props) {
    super(props);
  }

  renderProjectItem() {
    // method implemented in child components
  }

  render() {
    const { project, preorder } = this.props;
    const showColorCorrection =
      project.colorCorrectionTypes.length &&
      preorder.colorCorrectionCode === project.colorCorrectionTypes[0].id;

    return (
      <div>
        {this.renderProjectItem()}
        {preorder.designServiceCode && <DesignServiceItem project={project} preorder={preorder} />}
        {showColorCorrection && <ColorCorrectionItem project={project} preorder={preorder} />}
      </div>
    );
  }
}
