import { routers, routing } from 'outlinejs';

import { CheckoutController } from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('checkout:main', CheckoutController)
    };
  }
}
