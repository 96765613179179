import React from 'react';
import { components } from 'outlinejs';

import { AeModal } from '@photosi/albumepoca-ui';
import { EpocaBaseContentView } from '../core/views';
import { OrderSummary } from '../core/components/orderSummary';

import { CheckoutProjectItemHeader } from './components/checkoutProjectItemHeader';
import { CheckoutProjectComponent } from './components/checkoutProjectComponent';
import { AddressComponent } from './components/addressComponent';
import { DeliveryDaysComponent } from './components/deliveryDaysComponent';

import PaymentMethodsImage from './media/images/payment_methods.png';
import './styles/main.scss';

class CheckoutProjectsView extends components.BaseComponent {
  render() {
    return (
      <div className="row checkout-projects">
        <div className="cart-products-head col-sm-12 hidden-xs">
          <h5 className="cart-products-title text-uppercase">{this.i18n.gettext('Ordine')}</h5>
        </div>
        <div className="col-xs-12">
          <div className="row cart-products-body">
            <div className="col-xs-12 no-padding-col">
              <CheckoutProjectItemHeader />
              {this.props.cartProjects.collection.map((project) => (
                <CheckoutProjectComponent
                  key={project.id}
                  project={project}
                  delegate={this.props.delegate}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class CheckoutDeliveryView extends components.BaseComponent {
  render() {
    return (
      <div className="row checkout-delivery">
        <div className="cart-products-head col-sm-12 hidden-xs">
          <h5 className="cart-products-title text-uppercase">
            {this.i18n.gettext('Fatturazione e consegna')}
          </h5>
        </div>

        <div className="hidden-xs cart-project-item-header">
          <div className="col-sm-12">
            <div className="row cart-project-prices-header">
              <div className="col-sm-4 cart-project-prices-title text-left">
                <h5>{this.i18n.gettext('fatturazione')}</h5>
              </div>
              <div className="col-sm-4 cart-project-prices-title text-left">
                <h5>{this.i18n.gettext('dati destinatario')}</h5>
              </div>
              <div className="col-sm-4 cart-project-prices-title text-left">
                <h5>{this.i18n.gettext('consegna stimata')}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <hr className="cart-project-prices-header-divider" />
          </div>
        </div>

        <div className="col-sm-12 hidden-xs">
          <div className="row cart-project-prices-header">
            <div className="col-sm-4 cart-project-prices-title">
              <AddressComponent
                company={this.props.billingAddress.company}
                street={this.props.billingAddress.street}
                number={this.props.billingAddress.number}
                zipCode={this.props.billingAddress.zipCode}
                city={this.props.billingAddress.city}
                country={this.props.billingAddress.countryIsoName}
              />
            </div>
            <div className="col-sm-4 cart-project-prices-title">
              <AddressComponent
                company={this.props.shippingAddress.company}
                street={this.props.shippingAddress.street}
                number={this.props.shippingAddress.number}
                zipCode={this.props.shippingAddress.zipCode}
                city={this.props.shippingAddress.city}
                country={this.props.shippingAddress.countryIsoName}
              />
            </div>
            <div className="col-sm-4 cart-project-prices-title alert-info-text">
              <DeliveryDaysComponent days={this.props.deliveryDays} />
            </div>
          </div>
        </div>

        <div className="cart-products-head col-xs-12 visible-xs">
          <h5 className="cart-products-title text-uppercase">
            {this.i18n.gettext('Fatturazione')}
          </h5>
        </div>
        <div className="col-xs-12 visible-xs">
          <div className="row cart-project-prices-header">
            <div className="col-xs-12 cart-project-prices-title">
              <AddressComponent
                company={this.props.billingAddress.company}
                street={this.props.billingAddress.street}
                number={this.props.billingAddress.number}
                zipCode={this.props.billingAddress.zipCode}
                city={this.props.billingAddress.city}
                country={this.props.billingAddress.countryIsoName}
              />
            </div>
          </div>
        </div>

        <div className="cart-products-head col-xs-12 visible-xs">
          <h5 className="cart-products-title text-uppercase">
            {this.i18n.gettext('Dati destinatario')}
          </h5>
        </div>
        <div className="col-xs-12 visible-xs">
          <div className="row cart-project-prices-header">
            <div className="col-xs-12 cart-project-prices-title">
              <AddressComponent
                company={this.props.shippingAddress.company}
                street={this.props.shippingAddress.street}
                number={this.props.shippingAddress.number}
                zipCode={this.props.shippingAddress.zipCode}
                city={this.props.shippingAddress.city}
                country={this.props.shippingAddress.countryIsoName}
              />
            </div>
          </div>
        </div>

        <div className="cart-products-head col-xs-12 visible-xs">
          <h5 className="cart-products-title text-uppercase">
            {this.i18n.gettext('Consegna stimata')}
          </h5>
        </div>
        <div className="col-xs-12 visible-xs">
          <div className="row cart-project-prices-header">
            <div className="col-xs-12 cart-project-prices-title">
              <DeliveryDaysComponent days={this.props.deliveryDays} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class CheckoutPaymentView extends components.BaseComponent {
  render() {
    const paymentTypeLabels = {
      PP: this.i18n.gettext('PayPal'), // PAYPAL
      CC: this.i18n.gettext('Credit Card'), // CARTA DI CREDITO
      BO: this.i18n.gettext('Bank Transfer'), // BONIFICO
      RD: this.i18n.gettext('Direct remittance'), // RIMESSA DIRETTA
      AS: this.i18n.gettext('Check'), // ASSEGNO
      RI: this.i18n.gettext('Direct debit'), // RID
      RB: this.i18n.gettext('Bank draft') // RIBA
    };
    const { paymentType } = this.request.customerUser;
    const paymenTypeLabel = paymentTypeLabels[paymentType];

    return (
      <div className="row checkout-payment">
        <div className="cart-products-head col-xs-12">
          <h5 className="cart-products-title text-uppercase">
            {this.i18n.gettext('Metodo di pagamento')}
          </h5>
        </div>

        <div className="col-xs-12">
          <h4>{paymenTypeLabel}</h4>
          {paymentType === 'CC' ? (
            <img alt={'Payments'} className="hidden-xs" src={PaymentMethodsImage} />
          ) : null}
        </div>
      </div>
    );
  }
}

/**
 * Cart Grid Responsive Layout
 */
class CheckoutGridLayout extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.onCouponAdd = this.onCouponAdd.bind(this);
    this.onCouponRemove = this.onCouponRemove.bind(this);
    this.onMainButtonClick = this.onMainButtonClick.bind(this);
    this.onAddProductClick = this.onAddProductClick.bind(this);
    this.state = {
      showModal: false
    };
  }

  onCouponAdd(coupon) {
    this.props.delegate.addCoupon(coupon);
  }

  onCouponRemove(coupon) {
    this.props.delegate.removeCoupon(coupon);
  }

  onMainButtonClick() {
    if (this.props.delegate.isCheckoutValid()) {
      this.setState({ showModal: true });
    }
  }

  onDismissModal() {
    this.setState({ showModal: false });
  }

  onAddProductClick() {
    this.props.delegate.goToProductList();
  }

  callToActionLabel() {
    return this.i18n.gettext('Ordina');
  }

  confirmOrder() {
    this.setState({ showModal: false });
    if (this.props.delegate.directPayment()) {
      this.props.delegate.confirmInvoicePayment();
    } else {
      this.props.delegate.confirmOnlinePayment();
    }
  }

  renderOrderSummary() {
    return (
      <div>
        <OrderSummary
          numberOfProducts={this.props.cartHeader.model.numberOfProducts}
          subTotalWithoutVoucherValue={this.props.cartHeader.model.formattedSubTotalWithoutVoucher}
          hasDiscount={this.props.cartHeader.model.totalVoucher > 0}
          voucherValue={this.props.cartHeader.model.formattedTotalVoucher}
          delivery={this.props.cartHeader.model.formattedShippingCost}
          vat={this.props.cartHeader.model.formattedTotalVat}
          subTotal={this.props.cartHeader.model.formattedSubTotal}
          price={this.props.cartHeader.model.formattedTotalPriceWithVat}
          coupons={this.props.cartHeader.model.couponCodes}
          couponsLimit={this.props.cartHeader.model.couponsLimit}
          couponValid={this.props.cartHeader.validCoupon}
          onCouponAdd={this.onCouponAdd}
          onCouponRemove={this.onCouponRemove}
          onMainButtonClick={this.onMainButtonClick}
          onAddProductClick={this.onAddProductClick}
          mainButtonLabel={this.callToActionLabel()}
          hideAddProducts="true"
        />
        <AeModal
          isOpen={this.state.showModal}
          text={this.i18n.gettext("Confermi l'ordine?")}
          title={this.i18n.gettext('Attenzione conferma ordine')}
          confirmLabel={this.i18n.gettext('Sì e confermo la stampa')}
          dismissLabel={this.i18n.gettext('No e voglio controllarli')}
          onConfirm={this.confirmOrder.bind(this)}
          onDismiss={this.onDismissModal.bind(this)}
        />
      </div>
    );
  }

  renderCheckoutSection() {
    return (
      <div>
        <div className="col-xs-12">
          <CheckoutProjectsView {...this.props} />
          <CheckoutDeliveryView {...this.props} />
          <CheckoutPaymentView {...this.props} />

          <div className="row checkout-button hidden-xs hidden-sm hidden-md">
            <button className="btn btn-primary btn-lg btn-block" onClick={this.onMainButtonClick}>
              <span>{this.callToActionLabel()}</span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderBundle() {
    return <div />;
  }

  render() {
    return (
      <div className="row checkout-grid-layout">
        <div className="checkout-main-section col-lg-9">{this.renderCheckoutSection()}</div>
        <div className="checkout-order-summary col-lg-3">{this.renderOrderSummary()}</div>
        <div className="checkout-bundle-section col-lg-3">{this.renderBundle()}</div>
      </div>
    );
  }
}

/**
 * Main Checkout content View.
 *
 * NOTE: render method is defined in EpocaBaseContentView
 * */
export class CheckoutContentView extends EpocaBaseContentView {
  renderContent() {
    return <CheckoutGridLayout {...this.props} />;
  }
}
