import { components, conf } from 'outlinejs';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { AeGuideProjectItem } from './aeGuideProjectItem';
import { CheckoutProjectItem } from './checkoutProjectItem';

const CheckoutProjectItemDivider = () => (
  <div className="cart-project-item-divider">
    <div className="divider">
      <div className="fa-stack iconchain fa-lg">
        <i className="fa fa-circle fa-stack-2x " />
        <i className="fa fa-link fa-inverse fa-flip-horizontal fa-stack-1x" />
      </div>
    </div>
  </div>
);

export class CheckoutProjectComponent extends components.BaseComponent {
  render() {
    const { project } = this.props;
    const aeGuide = conf.settings.PRODUCT_TYPE_AE_GUIDE;
    const cartProjectHtmlContent = project.preorderCollection.map((preorder, index) => (
      <div key={preorder.id}>
        {project.preorders.length === 1 && preorder.productType === aeGuide ? (
          <AeGuideProjectItem
            preorder={preorder}
            project={project}
            delegate={this.props.delegate}
          />
        ) : (
          <CheckoutProjectItem
            preorder={preorder}
            project={project}
            delegate={this.props.delegate}
          />
        )}
        {index < project.preorderCollection.length - 1 ? <CheckoutProjectItemDivider /> : null}
      </div>
    ));

    const componentClass = classNames('row', 'cart-project-component', {
      'component-is-loading': this.props.delegate.cartProjectComponentIsLoading(project.id)
    });

    return (
      <div className={componentClass}>
        <div className="col-xs-12">{cartProjectHtmlContent}</div>
        <div className="col-xs-12">
          <hr className="cart-project-prices-header-divider" />
        </div>
      </div>
    );
  }
}

CheckoutProjectComponent.propTypes = {
  /**
   * Project data to visualize as cart item
   */
  project: PropTypes.object.isRequired
};
